<article >
  <mat-toolbar class="menu">
    <button mat-icon-button (click)="back()" *ngIf="!estadoModel.INGRESO_DATOS">
      <mat-icon>arrow_back_ios</mat-icon>
    </button>

    <div class="menu__logo">
      <img src="./assets/svg/daviplata-logo.svg" alt="daviplata-logo" />
    </div>

    <div></div>
  </mat-toolbar>
  <hr class="divisor" />

  @if(initLoading){
    <div class="loading">
      <ui-custom-spinner  [diameter]="'80'"  [mode]="'indeterminate'" [image]="'./assets/svg/clinning.svg'" ></ui-custom-spinner>
    </div>
  }@else{
    <div class="checkout" >
    
      <ng-container *ngIf="estado === estadoModel.INGRESO_CODIGO">
        <div class="codigo">
          <div class="codigo__logo">
            <img src="./assets/svg/clinning.svg" alt="" />
          </div>
  
          <p class="codigo__leyenda">
            <strong>Daviplata</strong> te enviará un mensaje de texto a tu móvil
            con el código que debes ingresar para confirmar tu pago.
          </p>
        </div>
      </ng-container>
  
      <ng-container *ngIf="estado === estadoModel.FINALIZADO">
        <div class="estado-transaccion">
          @if(success){
            <div class="estado-transaccion__icono estado-transaccion__icono--exito">
              <img src="./assets/icon/success.svg" alt="daviplata-logo" />
            </div>
            <p class="estado-transaccion__titulo">Transacción exitosa</p>
            <p class="estado-transaccion__total estado-transaccion__total--exito">
              {{operationInfo.operation.currencySymbol}} {{operationInfo.operation.amount}} 
            </p>
          }@else{
            <div class="estado-transaccion__icono estado-transaccion__icono--error">
              <img src="./assets/icon/error.svg" alt="daviplata-logo" />
           
            </div>
            <p class="estado-transaccion__titulo">Transacción rechazada</p>
            <p class="estado-transaccion__total estado-transaccion__total--error">
              {{operationInfo.operation.currencySymbol}} {{operationInfo.operation.amount}} 
            </p>
          }
  
        </div>
      </ng-container>
  
      <div class="checkout__contenedor">
        <ng-container
          *ngIf="
            estado === estadoModel.INGRESO_DATOS ||
            estado === estadoModel.FINALIZADO
          "
        >
          <div class="informacion">
            <div class="informacion__borde informacion__borde--top">
              <img src="./assets/svg/borde-top.svg" />
            </div>
            <p class="titulo">Detalle de pago</p>
  
            <ng-container *ngIf="estado === estadoModel.INGRESO_DATOS">
          
  
              <div class="informacion__item">
                <p class="informacion__item__titulo">Monto</p>
                <p
                  class="informacion__item__dato informacion__item__dato--resaltado"
                >
                  {{operationInfo.operation.currencySymbol}} {{operationInfo.operation.amount}} 
                </p>
              </div>
            </ng-container>
  
            <ng-container *ngIf="estado === estadoModel.FINALIZADO" >
           
              <div class="informacion__item">
                <p class="informacion__item__titulo">Monto</p>
                <p class="informacion__item__dato"> {{operationInfo.operation.currencySymbol}} {{operationInfo.operation.amount}} </p>
              </div>
  
              @if(success){
                <div class="informacion__item">
                  <p class="informacion__item__titulo">Número de solicitud</p>
                  <p class="informacion__item__dato">{{ operationInfo.approvalNumber}}</p>
                </div>
              } @else{
                <div class="informacion__item">
                  <p class="informacion__item__titulo">Error</p>
                  <p class="informacion__item__dato">{{ operationInfo.errorDescription}}</p>
                </div>
              }
  
              <div class="informacion__item">
                <p class="informacion__item__titulo">Fecha y hora</p>
                <p class="informacion__item__dato">{{ date | date: 'dd/MM/yyyy - h:mm a' }}</p>
              </div>
            </ng-container>
  
            <div class="informacion__monnet">
              <img src="./assets/svg/by-clinng.svg" alt="monnet-logo" />
            </div>
            <div class="informacion__borde informacion__borde--bottom">
              <img src="./assets/svg/borde-bottom.svg" />
            </div>
          </div>
        </ng-container>
  
        <div class="checkout__contenedor checkout__contenedor--centrado">
          <ng-container *ngIf="estado === estadoModel.INGRESO_DATOS">
            
            <p class="titulo">Ingresar datos</p>
  
            <div class="input-contenedor input-contenedor--union" [formGroup]="documentForm" >
              <label class="input-contenedor__label"> Documento y número </label>
  
              <div class="input-contenedor__union">
                <mat-select class="input-contenedor__select" name="select" formControlName="documentType" (selectionChange)="onDocumentTypeSelectChange($event.value)">
                 
                  <mat-option *ngFor="let document of documents" [value]="document.codeDocument">{{document.codeDocument}}</mat-option>
                </mat-select>
                
                <input
                  matInput
                  class="input-contenedor__input"
                  type="text"
                  placeholder="Ingresar número"
                  formControlName="documentNumber"
                  [maxlength]="maxlength"
                  [type]="type"
                  (input)="onFormatInput()"
                />
               
              </div>
              <mat-error class="form-error-message" *ngIf="documentForm.get('documentNumber')?.hasError('required') && documentForm.get('documentNumber')?.touched">
                El campo es requerido
              </mat-error>
              <mat-error class="form-error-message"  *ngIf="documentForm.get('documentNumber')?.hasError('minlength') && documentForm.get('documentNumber')?.touched" >
                Ingrese al menos {{minlength}} digitos
              </mat-error>
              <mat-error class="form-error-message"  *ngIf="documentForm.get('documentNumber')?.hasError('maxlength') && documentForm.get('documentNumber')?.touched" >
                Ingrese maximo {{minlength}} digitos
              </mat-error>
              <mat-error class="form-error-message"  *ngIf="errorMessage" >
                {{errorMessage}}
              </mat-error>
            
            </div>
          </ng-container>
  
          <ng-container *ngIf="estado === estadoModel.INGRESO_CODIGO">
            <div class="input-contenedor" [formGroup]="otpForm">
              <label class="input-contenedor__label" for="codigoOtp">
                Código OTP
              </label>
              <input
                class="input-contenedor__input"
                id="codigoOtp"
                type="text"
                placeholder="Ingresar código"
                formControlName="confirmValue"
                (input)="onFormatInputOTP()"
                [maxlength]="6"
              />
              <mat-error class="form-error-message" *ngIf="otpForm.get('confirmValue')?.hasError('required') && otpForm.get('confirmValue')?.touched">
                El campo es requerido
              </mat-error>
              <mat-error class="form-error-message" *ngIf="otpForm.get('confirmValue')?.hasError('minlength') && otpForm.get('confirmValue')?.touched">
              Debe ingresar 6 digitos
              </mat-error>
              <mat-error class="form-error-message" *ngIf="otpForm.get('confirmValue')?.hasError('maxlength') && otpForm.get('confirmValue')?.touched">
                Debe ingresar 6 digitos
              </mat-error>

              <p class="input-contenedor__timer" *ngIf="timer">Tiempo de espera: {{timer}}</p>
            </div>
           

            <p class="reenviar">¿No recibiste el código? <a (click)="initOperation()">Volver a solicitar</a></p>
          </ng-container>
        </div>
      </div>
  
      <div class="accionador">
        <ng-container
          *ngIf="estado === estadoModel.INGRESO_DATOS"
        >
        <div>
          
        </div>
        <div class="accionador__check">
          <mat-checkbox [(ngModel)]="aceptoTerminos" class="accionador__check__input" (click)="handleCheckboxClick($event)"></mat-checkbox>
          <span class="accionador__check__action">He leído y acepto los <strong class="accionador__check__action__lnk" (click)="downloadPdf('assets/pdf/tc.pdf','T&C.pdf', $event)">Términos y Condiciones</strong></span>
        </div>
        <div class="accionador__check">
          <mat-checkbox [(ngModel)]="aceptoPolitica" class="accionador__check__input" (click)="handleCheckboxClick($event)"></mat-checkbox>
          <span class="accionador__check__action">He leído y acepto la <strong class="accionador__check__action__lnk" (click)="downloadPdf('assets/pdf/politica.pdf','Política de Privacidad de Datos.pdf', $event)">Política de Tratamiento de Datos Personales</strong></span>
        </div>

        </ng-container>
  
        <ng-container *ngIf="estado === estadoModel.FINALIZADO">
          <div class="accionador__compartir" (click)="generatePdf()">
            <mat-icon >share</mat-icon>
            <p>Descargar constancia</p>
          </div>
        </ng-container>
        <button class="accionador__boton" 
        mat-button color="primary" 
        (click)="next()" 
        [disabled]="disabledButton()"
        [ngClass]="{'accionador__boton--disabled': disabledButton()}">
  
        @if (loading) {
          <div class="spinner" >
            <ui-custom-spinner [diameter]="'30'" [mode]="'indeterminate'" [showImage]="false"></ui-custom-spinner>
          </div>
        } @else{
          {{buttonText()}}
        }
        </button>
      </div>
    </div>
  }
</article>

<download-voucher [ngClass]="{'hidden': !download}" id="comprobante-pago" [success]="success" [operationInfo]="operationInfo" >
</download-voucher>
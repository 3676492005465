import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'clinng-download-button',
  standalone: true,
  imports: [CommonModule,MatIconModule],
  templateUrl: './ui-clinng-download-button.component.html',
  styleUrl: './ui-clinng-download-button.component.scss',
})
export class UiClinngDownloadButtonComponent {
  @Input() label: string = '';
  @Input() icon:string = '';
  @Output() onClick = new EventEmitter<any>();

  click(event?: MouseEvent) {
    this.onClick.emit(event);
  }
}

import { Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomSpinnerComponent } from '@angular-monorepo/ui';
import { NequiService, PaymentDetails } from '@angular-monorepo/api-services';
import { ActivatedRoute, Router } from '@angular/router';
import {DetailsModel, TicketDetails, UiClinngButtonComponent, UiClinngDownloadButtonComponent, UiClinngErrorModalComponent, UiClinngStaticHeaderComponent, UiClinngStatusAlertComponent, UiClinngTicketInfoComponent } from '@angular-monorepo/clinng-ui';

import { CaptureScreenService } from '@angular-monorepo/screenshot';
import { DownloadNequiVoucherComponent } from '../download-nequi-voucher/download-nequi-voucher.component';

import { CheckoutEstadoEnum } from '../../../model/states.enum';
import { ResponsiveService } from 'libs/shared/src/lib/services/responsive.service';
import { IconsEnum } from '../../../model/icons.enum';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import  moment from 'moment';


@Component({
  selector: 'checkout-nequi-form-container',
  standalone: true,
  imports: [CommonModule, FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    DownloadNequiVoucherComponent,
    CustomSpinnerComponent,
    UiClinngButtonComponent,
    UiClinngDownloadButtonComponent,
    UiClinngTicketInfoComponent,
    UiClinngStatusAlertComponent,
    UiClinngStaticHeaderComponent,
    UiClinngErrorModalComponent,
    MatDialogModule
  ],
  templateUrl: './checkout-nequi-form-container.component.html',
  styleUrl: './checkout-nequi-form-container.component.scss',
})
export class CheckoutNequiFormContainerComponent {
  esResponsive$ = this.responsiveService.esResponsive$;
  estado: CheckoutEstadoEnum = CheckoutEstadoEnum.INGRESO_DATOS;
  estadoModel = CheckoutEstadoEnum;
  detailsModel: DetailsModel;
  form: FormGroup;
  transactionId: string;
  verification: string;
  aceptoTerminos: boolean = false;
  operationInfo: PaymentDetails;
  ticketDetails: TicketDetails[] = [];
  download:boolean = false;
  success: boolean = false;
  intervalRef: any;
  timer: string;
  screenRecordService = inject(CaptureScreenService)
  loading: boolean;
  loadingButton: boolean = false;
  constructor(private nequiService: NequiService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private responsiveService: ResponsiveService,
  private matDialog: MatDialog) {}

  ngOnInit(): void {
  this.loading = true;
    this.initForm();
    this.route.params.subscribe(params => {
      this.transactionId = params['transactionId'];
    }
    );

    this.route.queryParams.subscribe(params => {
      this.verification = params['verification'];
    });

    this.getPaymentDetails();


    // this.refreshVoucher()

  }
  getPaymentDetails() {
    this.nequiService.getPaymentDetails(this.transactionId, this.verification).subscribe(
      {
        next: (data: any) => {
          this.operationInfo = data;
          this.setView()
          
        },
        error: (error) => {
          this.router.navigate(['/error/nequi']);
          this.loading = false;
        }
      })
  }

  setView(){
    if (this.operationInfo.processor.name != 'Nequi_wlt' || this.operationInfo.operation.status != '2') {
      this.router.navigate(['/error/nequi']);
    }

    if (this.operationInfo.operation.expirationDate){
      this.estado = CheckoutEstadoEnum.INGRESO_CODIGO;
      this.setTimer(this.operationInfo.operation.expirationDate);
    }

    this.loading = false;
  }

  initForm(): void {
    this.form = this.fb.group({
      phoneNumber: ['', [Validators.required, Validators.maxLength(10), Validators.minLength(9)]],
    });
  }

  formatValue(key: string) {
    // let formatDocument = this.form.controls[key].value.replace(/\D/g, '');
    //   this.form.controls[key].setValue(formatDocument);
  }
  phoneNumberRequiredValid(): boolean | undefined {
    return this.form.get('phoneNumber')?.hasError('required') && this.form.get('phoneNumber')?.touched
  }
  phoneNumberMinLengthValid(): boolean | undefined {
    return this.form.get('phoneNumber')?.hasError('minlength') && this.form.get('phoneNumber')?.touched
  }

  phoneNumberMaxLengthValid(): boolean | undefined {
    return this.form.get('phoneNumber')?.hasError('maxlength') && this.form.get('phoneNumber')?.touched
  }

  handleCheckboxClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  downloadPdf(event: MouseEvent): void {
  
    event.stopPropagation();
    const link = document.createElement('a');
    link.href = 'assets/pdf/nequi/tc.pdf';
    link.download = 'T&C.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    event.stopPropagation();
    const link2 = document.createElement('a');
    link2.href = 'assets/pdf/nequi/politica.pdf';
    link2.download = 'Política de Privacidad de Datos.pdf';
    document.body.appendChild(link2);
    link2.click();
    document.body.removeChild(link2);
  }

  initOperation() {
    this.loadingButton = true;
    this.nequiService.initOperation(this.transactionId, this.form.value, this.verification).subscribe(
      {
        next: (data: any) => {
          this.success = data.success;
          if (data.success) {
            this.estado = CheckoutEstadoEnum.INGRESO_CODIGO;
             this.setTimer(data.expirationDate);
          } else {
            let message = data.errorMessage || data.errorDescription || 'Error interno';
            this.setErrorDetails(message);
          }
          this.loadingButton = false;
        },
        error: (error) => {
          this.setErrorDetails();
          this.loadingButton = false;
        }
      })
  
  }

  confirmOperation() {
    this.loadingButton = true
    this.nequiService.confirmOperation(this.transactionId, this.form.value, this.verification).subscribe(
      {
        next: (data: any) => {
          this.success = data.success;
          this.setAlertDetails(data);
          this.setTicketDetails(data);

          this.estado = CheckoutEstadoEnum.FINALIZADO;
          this.loadingButton = false
        },
        error: (error) => {
          this.setErrorDetails();
          this.loadingButton = false
        }
      })
  }


  redirect(){
    if (this.estado === CheckoutEstadoEnum.FINALIZADO) {
      if (this.success) {
        window.location.href = this.operationInfo.operation.okUrl;
      } else {
        window.location.href = this.operationInfo.operation.errorUrl;
      }
    }
  }

 
  downloadVoucher(){
    this.download = true;
    setTimeout(() => {
     
      this.screenRecordService.captureWithPromise('comprobante-pago').then((imageData: string) => {
        this.download = false;
  
        const link = document.createElement('a');
        link.href = imageData;
        link.download = 'nequi-voucher.png';
        link.click();
      });
    }, 100);
  }



  setTimer(expirationDateResponse: string) {
    if (this.intervalRef) { clearInterval(this.intervalRef); }

    if(this.estado == CheckoutEstadoEnum.FINALIZADO || this.estado == CheckoutEstadoEnum.INGRESO_DATOS){
      return;
    }

    const currentDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
    const expirationDate = moment.utc(expirationDateResponse).format('YYYY-MM-DDTHH:mm:ss');
    const finalexpirationDate = moment.utc(expirationDate);
    const finalCurrentDate = moment.utc(currentDate);

    this.intervalRef = setInterval(() => {
      if(this.estado == CheckoutEstadoEnum.FINALIZADO || this.estado == CheckoutEstadoEnum.INGRESO_DATOS){
        if (this.intervalRef) { clearInterval(this.intervalRef); }
        return;
      }

      if (finalexpirationDate.isBefore(finalCurrentDate) || this.timer === '00 : 00') {
        clearInterval(this.intervalRef);
        this.expirateOperation();
        return;
      }
      // this.initLoading = false;
      let differenceInSeconds = finalexpirationDate.diff(moment.utc(), 'seconds');
      this.timer = moment.utc(differenceInSeconds * 1000).format('mm : ss');

    }, 1000);
  }


  expirateOperation() {
    this.nequiService.expirateOperation(this.transactionId, this.operationInfo.verificationResult).subscribe(
      ({
        next: (data: any) => {
          clearInterval(this.intervalRef);
          const dialog = this.matDialog.open(UiClinngErrorModalComponent, {
            width: '503px',
            height: '428px',
            disableClose: true,
            data: {
              url: this.operationInfo?.operation?.errorUrl
            }
        });
        },
        error: (error) => {
          clearInterval(this.intervalRef);
          const dialog = this.matDialog.open(UiClinngErrorModalComponent, {
            width: '503px',
            height: '428px',
            disableClose: true,
            data: {
              url: this.operationInfo?.operation?.errorUrl
            }
        });

        }
      })
    )
  }

  setTicketDetails(data: any) {
    const commonDetails = [
      {
        title: 'Número de línea',
        value: this.formatPhoneNumber(this.form.value.phoneNumber)
      },
      {
        title: 'Monto',
        value: `${this.operationInfo.operation.currencySymbol}${this.operationInfo.operation.amount}`
      },
      {
        title: 'Número de transacción',
        value: data.approvalNumber
      },
      {
        title: 'Fecha y hora',
        value:  moment.utc(data.operationDate).format('DD/MM/YYYY - h:mm A')
      }
    ];
  
    if (data.success) {
      this.ticketDetails = commonDetails;
    } else {
      this.ticketDetails = [
        ...commonDetails,
        {
          title: 'Error',
          value: data.errorDescription ? data.errorDescription : 'Error interno'
        }
      ];
    }
  }

  setAlertDetails(data: any) {
      this.detailsModel = {
        title: data.success ? 'Transacción exitosa' : 'Transacción rechazada',
        mount:this.operationInfo.operation.currencySymbol + '' + this.operationInfo.operation.amount,
        img:  data.success ? IconsEnum.SUCCESS : IconsEnum.ERROR,
        color: data.success ? 'blue' : 'red',
      }
  }


  setErrorDetails(message?: string) {
    this.setAlertDetails({success: false});
    this.ticketDetails = [
      {
        title: 'Número de línea',
        value: this.formatPhoneNumber(this.form.value.phoneNumber)
      },
      {
        title: 'Monto',
        value: this.operationInfo.operation.currencySymbol + '' + this.operationInfo.operation.amount
      },
      {
        title: 'Error',
        value: message ? message : 'Error interno'
      },
      {
        title: 'Número de transacción',
        value: this.operationInfo.operation.id.toString()
      },
      {
        title: 'Fecha y hora',
        value:  moment.utc().format('DD/MM/YYYY - h:mm A')
      }
    ]
    this.estado = CheckoutEstadoEnum.FINALIZADO;
  }


  formatPhoneNumber(phoneNumber: string): string {
    let cleanedValue = phoneNumber.replace(/\D+/g, '');
  
  
    let formattedValue = cleanedValue.replace(/(\d{3})(?=\d)/g, '$1-');
  
    return formattedValue;
  }
 
}

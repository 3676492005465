import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { UiClinngButtonComponent } from '../ui-clinng-button/ui-clinng-button.component';

@Component({
  selector: 'ui-clinng-error-modal',
  standalone: true,
  imports: [
    CommonModule,
    MatDialogModule,
    UiClinngButtonComponent
  ],
  templateUrl: './ui-clinng-error-modal.component.html',
  styleUrl: './ui-clinng-error-modal.component.scss',
})
export class UiClinngErrorModalComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {url:string}){}
  
  redirect(){
  window.location.href = this.data.url
  }
}

<div class="estado-transaccion">
  @if(detailsModel){
    <div class="estado-transaccion__icono">
      <img [src]="detailsModel.img" [alt]="detailsModel.title" />
    </div>
  
    <p class="estado-transaccion__titulo">{{detailsModel.title}}</p>
  
    <p class="estado-transaccion__subtitulo" *ngIf="detailsModel.subtitle">{{detailsModel.subtitle}}</p>
  
    <p class="estado-transaccion__total " [ngClass]="detailsModel.color ? detailsModel.color : null">
      {{detailsModel.mount}}
    </p>
  }
 
</div>
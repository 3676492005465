import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutDaviplataVoucherContainerComponent } from '@angular-monorepo/checkout-form-clinng';

import { DaviplataService } from '@angular-monorepo/api-services';

@Component({
  selector: 'angular-monorepo-voucher',
  standalone: true,
  imports: [CommonModule, CheckoutDaviplataVoucherContainerComponent],
  templateUrl: './voucher.component.html',
  styleUrl: './voucher.component.scss',
  providers:[DaviplataService]
})
export class VoucherComponent {}

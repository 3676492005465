<article >
    <ui-clinng-static-header [logo]="'./assets/img/pse-logo.png'" [alt]="'pse-logo'" [widthLogo]="'82px'" [heightLogo]="'83px'" [heightHeader]="'100px'"></ui-clinng-static-header>
    
      @if(initLoading){
        <div class="loading">
          <ui-custom-spinner  [diameter]="'80'"  [mode]="'indeterminate'" [image]="'./assets/svg/clinning.svg'" ></ui-custom-spinner>
        </div>
      }@else{
        <div class="checkout height-700"  >

    
        <ng-container >
         
    
        <ui-clinng-status-alert [detailsModel]="detailsModel" [mount]="'$ 3,000.00'" ></ui-clinng-status-alert>
  
        <ui-clinng-ticket-info [title]="'Detalle de pago'" [ticketDetails]="ticketDetails"></ui-clinng-ticket-info>
       
        <clinng-download-button *ngIf="showDownloadButton() " [icon]="'share'" [label]="'Descargar constancia'" (onClick)="downloadVoucher()" ></clinng-download-button>

        <ui-clinng-button [label]="'Retornar al comercio'" (onClick)="redirect()" ></ui-clinng-button>
        
      </ng-container>
             
          
   
        </div>
      }
    </article>


    <download-pse-voucher id="comprobante-pago"  
[ngClass]="{'hidden': !download}" 
[detailsModel]="detailsModel" 
[operationInfo]="operationInfo"
[ticketDetails]="ticketDetails"></download-pse-voucher>
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutClinngVoucherContainerComponent } from '@angular-monorepo/checkout-voucher-clinng';

@Component({
  selector: 'checkout-clinng-voucher',
  standalone: true,
  imports: [CommonModule,CheckoutClinngVoucherContainerComponent],
  templateUrl: './checkout-clinng-voucher.component.html',
  styleUrl: './checkout-clinng-voucher.component.scss',
})
export class CheckoutClinngVoucherComponent {}

<button class="button" 
        mat-button color="primary" 
        [disabled]="disabled"
        [ngClass]="{'button--disabled': disabled || loading}"
        (click)="click()">
 
  @if(loading){
    <ui-custom-spinner [diameter]="'30'" [mode]="'indeterminate'" [showImage]="false"></ui-custom-spinner>
  }@else{
    {{ label }}
  }
</button>
import { Component, inject, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { CustomSpinnerComponent, ICustomSelectModel } from '@angular-monorepo/ui';
import { DetailsModel, TicketDetails, UiClinngButtonComponent, UiClinngDownloadButtonComponent, UiClinngFormFieldComponent, UiClinngStaticHeaderComponent, UiClinngStatusAlertComponent, UiClinngTicketInfoComponent } from '@angular-monorepo/clinng-ui';
import { CheckoutEstadoEnum, CheckoutSubestadoFinalizadoEnum } from '../../../model/states.enum';
import { FormPseComponent } from '../form-pse/form-pse.component';
import { ResponsiveService } from 'libs/shared/src/lib/services/responsive.service';
import { MoviiCreateOperationRequest, MoviiService, MoviiSessionDetails, PaymentDetails } from '@angular-monorepo/api-services';
import { IconsEnum } from '../../../model/icons.enum';
import { DownloadPseVoucherComponent } from '../download-pse-voucher/download-pse-voucher.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';

@Component({
  selector: 'checkout-pse-form-container',
  standalone: true,
  imports: [CommonModule, FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    CustomSpinnerComponent,
    UiClinngButtonComponent,
    UiClinngDownloadButtonComponent,
    UiClinngTicketInfoComponent,
    UiClinngStatusAlertComponent,
    UiClinngStaticHeaderComponent,
    FormPseComponent, DownloadPseVoucherComponent],
  templateUrl: './checkout-pse-form-container.component.html',
  styleUrl: './checkout-pse-form-container.component.scss',
})
export class CheckoutPseFormContainerComponent {
  @ViewChild(FormPseComponent) formPseComponent: FormPseComponent;
  esResponsive$ = this.responsiveService.esResponsive$;

  estado: CheckoutEstadoEnum = CheckoutEstadoEnum.INGRESO_DATOS;
  estadoModel = CheckoutEstadoEnum;

  subEstado: CheckoutSubestadoFinalizadoEnum | null = null;
  subEstadoModel = CheckoutSubestadoFinalizadoEnum;
  detailsModel: DetailsModel;
  aceptoTerminos: boolean = false;

  operationInfo: MoviiSessionDetails;
  download: boolean = false;
  transactionId: string;
  verification: string;
  bankList: ICustomSelectModel[] = [];
  documentValues: any[] = [];
  loading: boolean = false;
  initLoading: boolean = true;
  constructor(
    private responsiveService: ResponsiveService,
    private moviiService: MoviiService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {

  }

  ngOnInit(): void {

    this.route.params.subscribe(params => { this.verification = params['verification'] });
    this.getPaymentDetails();
    this.getDocuments();

  }


  getDocuments() {
    this.moviiService.findDocumentByCountry().subscribe(
      (data: any) => {
        this.documentValues = data;
      },
      (error) => {
        console.error('Error en la solicitud:', error);
      }
    )


  }
  getPaymentDetails() {
    this.moviiService.getPaymentDetails(this.verification).subscribe({
      next: (data) => {
        this.operationInfo = data;

        this.bankList = data.bankList.map((bank) => {
          return {
            label: bank.name,
            value: bank.code
          }
        })

        this.initLoading = false;
      },
      error: (error) => {
        console.error('Error en la solicitud:', error);
        this.initLoading = false;
        this.router.navigate(['/error-status/movii']);
      },
    });



  }

  downloadPdf(event: MouseEvent): void {
    event.stopPropagation();
    const link = document.createElement('a');
    link.href = 'assets/pdf/pse/politica.pdf';
    link.download = 'Política de Privacidad de Datos.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  handleCheckboxClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  createOperation() {
    this.loading = true;
    const body: MoviiCreateOperationRequest = {
      sessionId: this.verification,
      bank: {
        code: this.formPseComponent.form.value.bank,
        name: this.formPseComponent.form.value.bankName
      },
      customer: {
        name: this.formPseComponent.form.value.name,
        typePerson: this.formPseComponent.form.value.type,
        documentType: this.formPseComponent.form.value.documentType,
        documentNumber: this.formPseComponent.form.value.documentNumber,
        email: this.formPseComponent.form.value.mail,
      }
    }


    this.moviiService.createOperation(body).subscribe({
      next: (data) => {

        if (data.operation.status == '6') {
          window.location.href = data.operation.errorUrl;
        } else {
          window.location.href = data.operation.bankUrl;
        }
        this.loading = false;
      },
      error: (error) => {
        this.snackBar.open('Error al crear la operación', '', {
          duration: 2000,
        });
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });



  }
}

import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { appRoutes } from './app.routes';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { EnvironmentService } from '@angular-monorepo/environment';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';

export const appConfig: ApplicationConfig = {
  providers: [provideRouter(appRoutes), provideAnimations(),
    importProvidersFrom(BrowserAnimationsModule, HttpClientModule),
    {
        provide: APP_INITIALIZER,
        useFactory: (configService: EnvironmentService) => () => configService.setConfig(environment),
        deps: [EnvironmentService],
        multi: true
    }, provideAnimations()],
};

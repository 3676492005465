<div class="form-input" [style.width.px]="width">
    <span class="form-input__title">{{label}}</span>
    <select name="options" id="options" [formControl]="control" (change)="onChange($event)"
        [ngClass]="{'error': hasError() }">
       <ng-container *ngFor="let option of valueOptions">
        <option [value]="option.value">{{ option.label }}</option>
       </ng-container>
    </select>
    <mat-error class="form-error-message" *ngIf="hasError()">
        {{ errorMessage }}
    </mat-error>
</div>

<div class="form-input" [style.width.px]="width">
  <span class="form-input__title">{{label}}</span>
  <input 
    [placeholder]="placeholder"
    [formControl]="control"
    [ngClass]="{'error': hasError() }"
    (input)="onChange($event)">
  <mat-error class="form-error-message" *ngIf="hasError()">
    {{ errorMessage }}
  </mat-error>
</div>
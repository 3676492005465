import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiClinngDoubleSelectFormFieldComponent, UiClinngFormFieldComponent, UiClinngSelectFormFieldComponent } from '@angular-monorepo/clinng-ui';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ICustomSelectModel } from '@angular-monorepo/ui';
import { ResponsiveService } from 'libs/shared/src/lib/services/responsive.service';

@Component({
  selector: 'form-pse',
  standalone: true,
  imports: [CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiClinngFormFieldComponent,
    UiClinngSelectFormFieldComponent,
    UiClinngDoubleSelectFormFieldComponent],
  templateUrl: './form-pse.component.html',
  styleUrl: './form-pse.component.scss',
})
export class FormPseComponent {
  @Input() bankList: ICustomSelectModel[] = [];
  @Input() documentValues: any[] = [];
  formattedDocumentValues: ICustomSelectModel[] = [];
  esResponsive$ = this.responsiveService.esMaxResponsive$;
  typeValues: ICustomSelectModel[] = [
    { label: 'JURÍDICA', value: 1 },
    { label: 'NATURAL', value: 2 },
  ]
  form: FormGroup;
  maxLength: number = 10;
  minLength: number = 6;

  constructor(private fb: FormBuilder, private responsiveService: ResponsiveService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      bank: [null, [Validators.required]],
      bankName: [null, [Validators.required]],
      type: [null, [Validators.required]],
      name: [null, [Validators.required]],
      documentType: [null, [Validators.required]],
      documentNumber: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(10)]],
      mail: [null, [Validators.required, Validators.email]],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['documentValues']) {
      this.formatDocuments()
    }

  }

  formatDocuments() {
    console.log(this.documentValues);

    this.formattedDocumentValues = this.documentValues.map((document) => {
      return {
        label: document.codeDocument,
        value: document.codeDocument
      }
    })
  }

  get BankControl(): FormControl {
    return this.form.get('bank') as FormControl;
  }

  get TypeControl(): FormControl {
    return this.form.get('type') as FormControl;
  }

  get NameControl(): FormControl {
    return this.form.get('name') as FormControl;
  }
  get DocumentTypeControl(): FormControl {
    return this.form.get('documentType') as FormControl;
  }
  get DocumentNumberControl(): FormControl {
    return this.form.get('documentNumber') as FormControl;
  }
  get MailControl(): FormControl {
    return this.form.get('mail') as FormControl;
  }


  errorMessageBank(): string {
    const control = this.BankControl;
    if (control.hasError('required')) {
      return 'El campo es requerido';
    }
    return '';
  }

  errorMessageType(): string {
    const control = this.TypeControl;
    if (control.hasError('required')) {
      return 'El campo es requerido';
    }
    return '';
  }

  errorMessageName(): string {
    const control = this.NameControl;
    if (control.hasError('required')) {
      return 'El campo es requerido';
    }
    return '';
  }


  errorMessageTypeAndDocumentNumber(): string {
    const DocumentNumberControl = this.DocumentNumberControl;
    if (DocumentNumberControl.hasError('required')) {
      return 'El campo es requerido';
    } else if (DocumentNumberControl.hasError('minlength') || DocumentNumberControl.hasError('maxlength')) {
      return `El campo debe tener entre ${this.minLength} y ${this.maxLength} dígitos`;
    }
    const DocumentTypeControl = this.DocumentTypeControl;
    if (DocumentTypeControl.hasError('required')) {
      return 'El campo es requerido';
    }
    return '';
  }

  errorMessageMail(): string {
    const control = this.MailControl;
    if (control.hasError('required')) {
      return 'El campo es requerido';
    } else if (control.hasError('email')) {
      return 'El correo no es válido';
    }
    return '';
  }


  onChangePhoneNumber($event: string): void {

  }

  onChangeDocumentTypeEvent(event: any): void {
    let document = this.documentValues.find((document) => document.codeDocument === event);

    if (document) {
      this.maxLength = document.lenghtMax;
      this.minLength = document.lenghtInit;

      this.DocumentNumberControl.setValidators([
        Validators.required,
        Validators.minLength(this.minLength),
        Validators.maxLength(this.maxLength)
      ]);

      this.DocumentNumberControl.updateValueAndValidity();
    }
  }

  onBankChange(event: any): void {
    let bank = this.bankList.find((bank) => bank.value === event.value);
    this.form.get('bankName')?.setValue(bank?.label);
  }

  nameChange(event: any): void {
    this.NameControl.setValue(event.toUpperCase());
  }

}

export enum CheckoutEstadoEnum {
    INGRESO_DATOS = 1,
    INGRESO_CODIGO = 2,
    FINALIZADO = 3,
}

export enum CheckoutSubestadoFinalizadoEnum{
    EN_PROCESO = 1,
    EXITOSO = 2,
    FALLIDO = 3,
}
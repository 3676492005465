import { EnvironmentService } from '@angular-monorepo/environment';
import { Injectable, WritableSignal, computed, signal } from '@angular/core';
import { catchError, take, tap } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { State } from '../../interfaces';
import { GetPaymentPayloadVoucherRequest } from './payin-voucher.interface';
import { VoucherResponse } from '@angular-monorepo/ui';

const INITIAL_STATE: State<VoucherResponse> = {
  data: {} as VoucherResponse,
  loading: false,
  error: undefined,
};

@Injectable({
  providedIn: 'root'
})
export class VoucherPayinService {
  readonly #getPaymentData: WritableSignal<State<VoucherResponse>> = signal<State<VoucherResponse>>(INITIAL_STATE);

  public paymentData = computed(() => this.#getPaymentData().data)
  public paymentLoading = computed(() => this.#getPaymentData().loading)
  public getPaymentError = computed(() => this.#getPaymentData().error)

  constructor(
    private environmentService: EnvironmentService,
    private http: HttpClient,
  ) { }

  getVoucherDetails(params: GetPaymentPayloadVoucherRequest): void {
    const headers = new HttpHeaders({
      'verification':  params.verification
    });
    this.#getPaymentData.set({ data: {} as VoucherResponse, loading: true });
    this.http
      .post<VoucherResponse>(`${ this.environmentService?.env?.apiConfig?.payinCheckoutBackendUrl }common/operation/find/voucher/${ params.operationId }`, {}, { headers })
      .pipe(
        take(1),
        tap( ( response: VoucherResponse ) => {
          response?.voucherData?.generalInstructions?.forEach( ( instruction ) => {
            instruction?.devices.forEach( ( device ) => {
              device.instructions = device.instructions.map(
                ( instruction, index: number ) => {
                  if ( typeof instruction === 'string' ) {
                    return { value: instruction, order: index + 1 };
                  }
                  return instruction;
                }
              );
            } );
          } );
          this.#getPaymentData.set( { data: response, loading: false } );
        }),
        catchError((error) => {
          this.#getPaymentData.set({ data: {} as VoucherResponse, loading: false, error: error })
          throw error;
        })
      ).subscribe();
  }
}



import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ui-clinng-static-header',
  standalone: true,
  imports: [CommonModule,MatToolbarModule,MatIconModule],
  templateUrl: './ui-clinng-static-header.component.html',
  styleUrl: './ui-clinng-static-header.component.scss',
})
export class UiClinngStaticHeaderComponent {
  @Input() logo: string = '';
  @Input() alt: string = '';
  @Input() heightHeader: string = '';
  @Input() widthLogo: string = '';
  @Input() heightLogo: string = '';
}

import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NxWelcomeComponent } from './nx-welcome.component';
import { VoucherComponent } from './pages/voucher/voucher.component';

@Component({
  standalone: true,
  imports: [NxWelcomeComponent, RouterModule, VoucherComponent],
  selector: 'angular-monorepo-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'checkout-daviplata';
}

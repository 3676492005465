<form class="form" [formGroup]="form">
  <h3 class="form--title">Información de pago</h3>

  <div class="form__section">

    <div class="form__section__inputs margin-form">
      <ui-clinng-select-form-field class="margin-mobile" [label]="'Banco'" [control]="BankControl"
        [width]="(esResponsive$ | async) ? 312 : 215 " [placeholder]="'Selecciona'" [valueOptions]="bankList"
        [errorMessage]="errorMessageBank()" (onChangeEvent)="onBankChange($event)">
      </ui-clinng-select-form-field>

      <ui-clinng-select-form-field class="margin-mobile margin-tipo-persona" [label]="'Tipo de persona'" [control]="TypeControl"
        [width]="(esResponsive$ | async) ? 312 : 215" [placeholder]="'Selecciona'" [valueOptions]="typeValues"
        [errorMessage]="errorMessageType()">
      </ui-clinng-select-form-field>
    </div>

    <ui-clinng-form-field class="margin-mobile" style="margin-top: 2rem;" [label]="'Nombres y apellidos'" [width]="(esResponsive$ | async) ? 312 : 476"
      [placeholder]="'Ingresar tu información'" [control]="NameControl" [errorMessage]="errorMessageName()" (onChangeEvent)="nameChange($event)">
    </ui-clinng-form-field>
    <div class="form__section__inputs">

      <ui-clinng-double-select-form-field class="margin-mobile" 
        [width]="(esResponsive$ | async) ? 312 : 215"
        [label]="'Tipo y número de documento'" 
        [maxlength]="maxLength"
        [widthInput]="(esResponsive$ | async) ? 198  : 152"
        [widthSelect]="(esResponsive$ | async) ? 123 : 60" 
        [valueOptions]="formattedDocumentValues" 
        [controlInput]="DocumentNumberControl" 
        [errorMessage]="errorMessageTypeAndDocumentNumber()"
        [controlSelect]="DocumentTypeControl"
        (onChangeEvent)="onChangeDocumentTypeEvent($event)"
        >
      </ui-clinng-double-select-form-field>

      <ui-clinng-form-field class="margin-mobile" [label]="'Correo electrónico'" [width]="(esResponsive$ | async) ? 312 : 215"
        [placeholder]="'Ingresa tu correo'" [control]="MailControl" [errorMessage]="errorMessageMail()">
      </ui-clinng-form-field>
    </div>

    <div class="form--processed">
      <span>Procesado por</span>
      <img src="./assets/img/procesado-por-clinng.png" alt="">
    </div>
  </div>


</form>
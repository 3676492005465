<article>
  <ui-clinng-static-header [logo]="'./assets/svg/nequi-logo.svg'" [alt]="'nequi-logo'"></ui-clinng-static-header>

  @if(loading){
  <div class="loading">
    <ui-custom-spinner [diameter]="'80'" [mode]="'indeterminate'"
      [image]="'./assets/svg/clinning.svg'"></ui-custom-spinner>
  </div>
  }@else{
  <div class="checkout" [ngClass]="{'height-700': estado === estadoModel.FINALIZADO && !(esResponsive$ | async)}">
    <ng-container *ngIf="estado === estadoModel.INGRESO_DATOS">

      <div class="checkout__form" [formGroup]="form">
        <h3 class="checkout__form--title">Información de pago</h3>

        <div class="checkout__form--input">
          <span class="checkout__form--input__title">Número de télefono asociado</span>
          <input (input)="formatValue('phoneNumber')"
            [ngClass]="{'error': phoneNumberRequiredValid() || phoneNumberMaxLengthValid()|| phoneNumberMinLengthValid() }"
            formControlName="phoneNumber" placeholder="Ingresar número...">
          <mat-error class="form-error-message" *ngIf="phoneNumberRequiredValid()">
            El campo es requerido
          </mat-error>
          <mat-error class="form-error-message" *ngIf="phoneNumberMaxLengthValid()">
            El campo tiene un máximo de 10 dígitos
          </mat-error>
          <mat-error class="form-error-message" *ngIf="phoneNumberMinLengthValid()">
            El campo tiene un mínimo de 9 dígitos
          </mat-error>
        </div>
        <div class="checkout__form--info">
          <img src="./assets/svg/clock.svg" alt="clack">
          <p>Acepta la notificación de tu App de Nequi durante los próximos 15min</p>
        </div>

        <div class="checkout__form--processed">
          <span>Procesado por</span>
          <img src="./assets/img/procesado-por-clinng.png" alt="">
        </div>

      </div>
      <div class="checkout__actions">
        <div class="checkout__actions__check">
          <mat-checkbox [(ngModel)]="aceptoTerminos" class="checkout__actions__check__input"
            (click)="handleCheckboxClick($event)"></mat-checkbox>
          <div class="checkout__actions__check__text">
            <span class="checkout__actions__check__action">He leído y acepto los <strong
                class="checkout__actions__check__action__lnk" (click)="downloadPdf( $event)">Términos y Condiciones y
                Politica de Tratamiento de Datos</strong></span>
          </div>
        </div>

        <ui-clinng-button
          [label]=" (esResponsive$ | async) ? 'Pagar '+ operationInfo.operation.currencySymbol + operationInfo.operation.amount : 'Pagar'"
          [disabled]="form.invalid || !aceptoTerminos || loadingButton" (onClick)="initOperation()"
          [loading]="loadingButton"></ui-clinng-button>
      </div>
    </ng-container>

    <ng-container *ngIf="estado === estadoModel.INGRESO_CODIGO">

      <div class="tutorial">
        <div class="tutorial__header">
          <h3>Instrucciones</h3>
        </div>

        <div class="tutorial__stepper">
          <div class="tutorial__stepper__step">
            <div class="tutorial__stepper__step__body">
              <div class="tutorial__stepper__step__body__circle">
                <img src="./assets/svg/circle.svg" alt="">
                <span>1</span>
            </div>
              
            <img class="tutorial__stepper__step__icon" src="./assets/svg/phone.svg" alt="">
            </div>
            
            <div class="tutorial__stepper__step__description">
              <p class="intruction-text">Ingresa a tu <br> <span class="purple-text">App Nequi.</span></p>
            </div>
          </div>

          <div class="tutorial__stepper__step">
            <div class="tutorial__stepper__step__body">
              <div class="tutorial__stepper__step__body__circle">
                <img src="./assets/svg/circle.svg" alt="">
                <span>2</span>
            </div>
              
            <img class="tutorial__stepper__step__icon" src="./assets/svg/bell.svg" alt="">
            </div>
            
            <div class="tutorial__stepper__step__description">
              <p class="intruction-text">Ve a la <span class="purple-text">campanita</span> <br> de notificaciones y <br> confirma el pago.  </p>
            </div>
          </div>


          <div class="tutorial__stepper__step">
            <div class="tutorial__stepper__step__body">
              <div class="tutorial__stepper__step__body__circle">
                <img src="./assets/svg/circle.svg" alt="">
                <span>3</span>
            </div>
              
            <img class="tutorial__stepper__step__icon" src="./assets/svg/checklist.svg" alt="">
            </div>
            
            <div class="tutorial__stepper__step__description">
              <p class="intruction-text">Vuelve aquí y dale click en el botón<br>
                <span class="purple-text">“Ya acepté”</span> y ¡listo!</p>
            </div>
          </div>
 
       

       
        </div>
      </div>

      <div class="checkout__timer">
        <img src="./assets/svg/clock.svg" alt="clack">
        <span>Tiempo de espera: <strong> {{timer}} </strong> </span>
      </div>


      <ui-clinng-button [label]="'Ya acepté'" (onClick)="confirmOperation()" [disabled]="loadingButton"
        [loading]="loadingButton"></ui-clinng-button>

    </ng-container>
    <ng-container *ngIf="estado === estadoModel.FINALIZADO">

      <ui-clinng-status-alert [detailsModel]="detailsModel"
        [mount]="operationInfo.operation.currencySymbol + '' + operationInfo.operation.amount"></ui-clinng-status-alert>

      <ui-clinng-ticket-info [title]="'Detalle de pago'" [ticketDetails]="ticketDetails"></ui-clinng-ticket-info>

      <clinng-download-button [icon]="'share'" [label]="'Descargar constancia'"
        (onClick)="downloadVoucher()"></clinng-download-button>

      <ui-clinng-button [label]="'Retornar al comercio'" (onClick)="redirect()"></ui-clinng-button>

    </ng-container>



  </div>
  }
</article>

<download-nequi-voucher id="comprobante-pago" [ngClass]="{'hidden': !download}" [detailsModel]="detailsModel"
  [operationInfo]="operationInfo" [ticketDetails]="ticketDetails">
</download-nequi-voucher>
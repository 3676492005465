import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UiClinngStaticHeaderComponent } from '../ui-clinng-static-header/ui-clinng-static-header.component';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'ui-clinng-error-page',
  standalone: true,
  imports: [CommonModule,UiClinngStaticHeaderComponent,MatIconModule],
  templateUrl: './ui-clinng-error-page.component.html',
  styleUrl: './ui-clinng-error-page.component.scss',
})
export class UiClinngErrorPageComponent {
  @Input() logo:string = '';
  @Input() alt:string = '';
  title:string = '¡Ups! Ocurrió un problema';
  subtitle:string = 'Lo sentimos, vuelve a intentarlo más tarde.'
  
  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.url.subscribe((url) => {
      if(url[0].path == 'error-status'){
        this.subtitle = 'Lo sentimos, esta transacción expiró. <br> Intenta un nuevo pago'
      }
    });
   }
}

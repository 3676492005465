<div class="payment-detail">
  <div class="payment-detail__border payment-detail__border--top">
    <img src="assets/svg/borde-top.svg" alt="border-top" />
  </div>
  <p class="payment-detail__title">Detalle de pago</p>

  <ng-container *ngFor="let item of ticketDetails">
    <div class="payment-detail__item">
      <p class="payment-detail__item-title">{{item.title}}</p>
      <p class="payment-detail__item-data"> {{item.value}}</p>
    </div>
  </ng-container>

  <div class="payment-detail__monnet">
    <img src="assets/svg/by-clinng.svg" alt="monnet-logo" />
  </div>
  <div class="payment-detail__border payment-detail__border--bottom">
    <img src="assets/svg/borde-bottom.svg" alt="border-bottom" />
  </div>
</div>

<article>
    <ui-clinng-static-header [logo]="'./assets/svg/nequi-logo.svg'"  [alt]="'nequi-logo'"></ui-clinng-static-header>
  
    <div class="checkout">

        <div></div>

        <div>
            <h5>Constancia de operación</h5>
            <ui-clinng-status-alert [detailsModel]="detailsModel" [mount]="operationInfo.operation.currencySymbol + '' + operationInfo.operation.amount"></ui-clinng-status-alert>
        </div>
       

        <ui-clinng-ticket-info [title]="'Detalle de pago'" [ticketDetails]="ticketDetails"></ui-clinng-ticket-info>



        <div></div>


    </div>
</article>


import { Component, Input, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
export interface TicketDetails{
  title:string
  value:string
}
@Component({
  selector: 'ui-clinng-ticket-info',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ui-clinng-ticket-info.component.html',
  styleUrl: './ui-clinng-ticket-info.component.scss',
})
export class UiClinngTicketInfoComponent {
  @Input() title:string
  @Input() ticketDetails: TicketDetails[];
  ngOnInit(): void {

  }
  ngOnChanges(changes: SimpleChanges): void {
   
  }
}

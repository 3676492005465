import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailsModel, TicketDetails, UiClinngButtonComponent, UiClinngDownloadButtonComponent, UiClinngStaticHeaderComponent, UiClinngStatusAlertComponent, UiClinngTicketInfoComponent } from '@angular-monorepo/clinng-ui';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'download-pse-voucher',
  standalone: true,
  imports: [CommonModule,UiClinngButtonComponent,
    UiClinngDownloadButtonComponent,
    UiClinngTicketInfoComponent,
    UiClinngStatusAlertComponent,
  MatToolbarModule,
MatIconModule,
UiClinngStaticHeaderComponent
],
  templateUrl: './download-pse-voucher.component.html',
  styleUrl: './download-pse-voucher.component.scss',
})
export class DownloadPseVoucherComponent {
  @Input() detailsModel: DetailsModel;
  @Input() operationInfo: any;
  @Input() ticketDetails: TicketDetails[] = [];
}

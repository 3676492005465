<article >
    <ui-clinng-static-header [logo]="logo"  [alt]="alt"></ui-clinng-static-header>

  
    <div class="container">
        <div class="container__icono">
            <mat-icon>info</mat-icon>
          </div>
          <p class="container__titulo" [innerHTML]="title"></p>
          <p class="container__subtitulo" [innerHTML]="subtitle"></p>

          <img class="container__imagen" src="./assets/svg/clining-error.svg" alt="clining-error">
    </div>
  
  </article>
import { Component, effect, EventEmitter, inject, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent, CustomSpinnerComponent, MediaButton } from '@angular-monorepo/ui';
import { Banner, BannerComponent, InstructionStepper, LinearStepFontSize, LinearStepInfo, LinearStepStatusColor } from '@angular-monorepo/shared';
import { PvDefaultHeaderComponent } from 'libs/checkout-payin-voucher/src/lib/components/default-voucher/pv-default-header/pv-default-header.component';
import { VoucherHeaderComponent } from 'libs/direct-connections-voucher/src/lib/components/voucher-header/voucher-header.component';
import { CountdownComponent, CountdownModule } from 'ngx-countdown';
import { DirectConnectionsVoucher } from 'libs/direct-connections-voucher/src/lib/components/direct-connections-voucher/direct-connections-voucher.interface';
import { DirectConnectionsVoucherComponent } from 'libs/direct-connections-voucher/src/lib/components/direct-connections-voucher/direct-connections-voucher.component';
import { VoucherPdfContainerComponent } from 'libs/direct-connections-voucher/src/lib/components/voucher-pdf-container/voucher-pdf-container.component';

import { PdfJsService } from '@angular-monorepo/pdf';
import { CaptureScreenService } from '@angular-monorepo/screenshot';
import { CashProcessorService, GetPaymentPayloadRequest, GetPaymentResponseRequest, PaymentMethodInstruction, PaymentMethodPersonaType } from '@angular-monorepo/api-services';
import { ProcessorNetworkseComponent, Processors, ProcessorSelectableComponent } from '@angular-monorepo/processor-selection';
import { bankTransferPaymentMethodButton, cashPaymentMethodButton, walletPaymentMethodButton } from 'libs/direct-connections-voucher/src/lib/components/direct-connections-voucher-container/direct-connections-voucher.utils';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { EnvironmentService } from '@angular-monorepo/environment';

@Component({
  selector: 'checkout-clinng-voucher-container',
  standalone: true,
  imports: [
    CommonModule,
    CustomSpinnerComponent,
    CardComponent,
    BannerComponent,
    PvDefaultHeaderComponent,
    VoucherHeaderComponent,
    CountdownModule,
    DirectConnectionsVoucherComponent,
    VoucherPdfContainerComponent,
    ProcessorSelectableComponent,
    ProcessorNetworkseComponent
  ],
  templateUrl: './checkout-clinng-voucher-container.component.html',
  styleUrl: './checkout-clinng-voucher-container.component.scss',
})
export class CheckoutClinngVoucherContainerComponent {
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  @Output() onShowTemplate: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  jsPdfService = inject(PdfJsService)
  screenRecordService = inject(CaptureScreenService)

  showTemplate: boolean = false

  isLoading = false;

  expirationTime: number = 0;
  expirationTimeFormated: string = '';

  directConnectionsVoucherInput: DirectConnectionsVoucher = {} as DirectConnectionsVoucher;
  paymentParemeters: GetPaymentPayloadRequest = {} as GetPaymentPayloadRequest;

  voucherData: GetPaymentResponseRequest = null as unknown as any;
  selectedProcessor: Processors = null as any

  linearSteps: LinearStepInfo[] = []
  processors: Processors[] = []

  paymentMethod: string = 'Cash'

  get bankTransferButton(): MediaButton {
    return bankTransferPaymentMethodButton(this.paymentMethod, () => {
      this.paymentMethod = 'BankTransfer'
      this.changeProcessorInstructions()
    })

  }

  get banner(): Banner {
    return {
      processor: this.selectedProcessor.name,
      text: 'Estás Pagando con',
      img: this.selectedProcessor.img,
      background: this.selectedProcessor.hexColor || '',
      colorText: '#2B2F3A'
    };
  }
  get cashButton(): MediaButton {
    return cashPaymentMethodButton(this.paymentMethod, () => {
      this.paymentMethod = 'Cash'
      this.changeProcessorInstructions()
    })
  }

  get walletButton(): MediaButton {
    return walletPaymentMethodButton(this.paymentMethod, () => {
      this.paymentMethod = 'Wallet'
      this.changeProcessorInstructions()
    })
  }



  get instructions(): PaymentMethodInstruction[] {
    let instructions = this.voucherData?.voucherConfig?.bankList.find((bankInstruction) => bankInstruction.bankCode === this.selectedProcessor?.name)?.paymentMethodInstructions

    return instructions || []
  }

  constructor(
    private cashProcessorService: CashProcessorService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private environmentService: EnvironmentService
  ) {
    effect(() => {

      this.isLoading = this.cashProcessorService.paymentLoading()

      if (this.cashProcessorService.paymentData().voucherConfig) {
        this.voucherData = { ...this.cashProcessorService.paymentData(), voucherConfig: JSON.parse(this.cashProcessorService.paymentData()?.voucherConfig as unknown as any) }
        this.paymentMethod = this.voucherData.voucherConfig.methodSelected || this.voucherData.voucherConfig.instruction_payment_methods[0]
        this.initTimer()

      }

      if (this.cashProcessorService.getPaymentError() || this.cashProcessorService.paymentData().errorMsg) {
        this.router.navigate(['/error'])
      }
      this.refreshVoucher()

    })

    effect(() => {
      if (this.screenRecordService.generatedImage()) {
        (() => {
          this.downloadImage(this.screenRecordService.generatedImage())
          this.onShowTemplateFunc(false)
          window.location.reload()
        })()
      }
    })
  }

  ngOnInit() {
    this.paymentParemeters = {
      operationId: this.activatedRoute.snapshot.params['operationId'],
      merchantId: this.activatedRoute.snapshot.params['merchantId'],
      verification: this.activatedRoute.snapshot.queryParams['verification'],
    }

    this.getPaymentInfo()
    this.onCreateIntervalToChangeStatus()
    this.setGetVoucherDataInterval()
  }

  refreshVoucher(): void {
    this.initLinearSteps()
    this.initInstructionSteps()
    this.initProcessors()
    this.changeProcessorInstructions()
  }

  metodoPagoTipoPersona: PaymentMethodPersonaType;
  handleCambioMetodo(metodo: PaymentMethodPersonaType) {
    this.metodoPagoTipoPersona = metodo;
  }
  
  initProcessors(): void {
    const environment = this.environmentService?.env?.production ? 'prod' : 'dev';
    this.processors = this.voucherData?.voucherConfig?.bankList.map((bank) => {
      return {
        name: bank?.bankCode || '',
        img: `https://d3os9iahmyv2xb.cloudfront.net/${environment}/voucher/V1/collectors/${bank.bankCode}/banner.svg`,
        hexColor: bank?.hexColor || '',
      } as unknown as Processors
    }) || []
    this.selectedProcessor = this.processors[0]
  }

  initLinearSteps(): void {
    const steps = []

    const amount: LinearStepInfo = {
      label: {
        label: 'Monto a pagar',
        value: `<b>${this.voucherData?.currencyISO} ${this.voucherData?.amount}</b>`,
        fontSize: LinearStepFontSize.SMALL,
      },
    }

    const paymentCode: LinearStepInfo = {
      label: {
        label: 'Código de pago',
        value: `<b>${this.voucherData?.paymentCode}</b>`,
        color: LinearStepStatusColor.PURPLE,
        fontSize: LinearStepFontSize.LARGE,
      },
      clipboard: true,
      clipboardText: `${this.voucherData?.paymentCode}`,
    }

    if (window.innerWidth <= 600) {
      steps.push(paymentCode)
      steps.push(amount)
    }

    if (window.innerWidth > 600) {
      steps.push(amount)
      steps.push(paymentCode)
    }

    if (this.voucherData?.status === 'EXPIRADO') {
      steps.push({
        label: {
          label: `Estado`,
          value: 'Tiempo expirado',
          color: LinearStepStatusColor.RED,
          fontSize: LinearStepFontSize.SMALL,
        },
      })
    }

    if (this.voucherData?.status === 'AUTORIZADO') {
      steps.push({
        label: {
          label: `Estado`,
          value: 'Pago Realizado',
          color: LinearStepStatusColor.GREEN,
          fontSize: LinearStepFontSize.SMALL,
        },
      })
    }

    if (this.voucherData?.status === 'PENDIENTE_PAGO') {
      steps.push({
        label: {
          label: `Vence en`,
          value: `<b>${this.expirationTimeFormated}</b> horas`,
          fontSize: LinearStepFontSize.SMALL,
        },
      })
    }

    this.linearSteps = steps
  }

  initInstructionSteps(): void {
    let directConnectionsVoucherData = {
      paymentMethodButtons: [],
      downloadButton: {
        imagesUrl: ['./assets/svg/download-file.svg'],
        label: 'Descargar instrucciones',
        color: '#6A00F4',
        onClick: () => {
          this.generatePdf()
        },
      },
      instructions: []
    }

    this.directConnectionsVoucherInput = directConnectionsVoucherData as unknown as DirectConnectionsVoucher
  }

  changeProcessorInstructions(): void {
    let instruction: InstructionStepper[] = this.findInstruction()
    let buttons: MediaButton[] = []

    this.uploadButton([])

    let cashMethod = this.findCashMethod()
    let bankTransferMethod = this.findBankTransferMethod()
    let walletMethod = this.findWalletMethod()

    this.updateVoucherInstructions(instruction)

    if (bankTransferMethod) {
      buttons.push(this.bankTransferButton)
    }

    if (cashMethod) {
      buttons.push(this.cashButton)
    }

    if (walletMethod) {
      buttons.push(this.walletButton)
    }

    this.uploadButton(buttons)
  }

  uploadButton(media: MediaButton[]): void {
    this.directConnectionsVoucherInput = {
      ...this.directConnectionsVoucherInput,
      paymentMethodButtons: media,
    }
  }

  updateVoucherInstructions(instructions: InstructionStepper[]): void {
    this.directConnectionsVoucherInput = {
      ...this.directConnectionsVoucherInput,
      instructions: instructions,
    }
  }

  findInstruction(): InstructionStepper[] {
    if (!this.voucherData) {
      return []
    }

    const collectorsByMethod = this.voucherData.voucherConfig?.bankList?.filter(b => {
      if(b.paymentMethodInstructions) {
        return b.paymentMethodInstructions?.some(instruction => instruction.method === this.paymentMethod)
      }
      return false
    });
    const processor = collectorsByMethod?.find(b => b.bankCode === this.selectedProcessor.name);
    if (processor) {
      const method = processor?.paymentMethodInstructions.find(m => m.method === this.paymentMethod);
      if (method) {
        const instructions = method?.instructions.map(i => {
          return {
            step: i.order,
            description: i.value,
          } as InstructionStepper
        })

        return instructions
      }
    }

    return []
  }

  findCashMethod(): PaymentMethodInstruction {
    return this.findPaymentMethodInstruction('Cash') || null as any
  }

  findBankTransferMethod(): PaymentMethodInstruction {
    return this.findPaymentMethodInstruction('BankTransfer') || null as any
  }

  findWalletMethod(): PaymentMethodInstruction {
    return this.findPaymentMethodInstruction('Wallet') || null as any
  }

  findPaymentMethodInstruction(method: string): PaymentMethodInstruction | undefined {
    return this.instructions?.find((instruction) => instruction.method === method)
  }

  findProcessor(code: string): Processors {
    return this.processors.find((p) => p.name === code) as Processors;
  }

  initTimer(): void {
    const expirationDate = moment(this.voucherData?.expiration).utc(true).diff(moment(), 'seconds')
    this.expirationTime = expirationDate;

    if (this.expirationTime > 86400) {
      const format = this.expirationTime > 86400 * 2 ? "d 'días' HH:mm:ss" : "d 'día' HH:mm:ss"
      const expirationTime = this.expirationTime - 86400
      this.countdown.config = {
        leftTime: expirationTime,
        format: format,
      };
    } else {
      this.countdown.config = {
        leftTime: this.expirationTime,
        format: "HH:mm:ss",
      };
    }


    this.countdown.restart()
  }

  getPaymentInfo(): void {
    this.cashProcessorService.getPayment(this.paymentParemeters)
  }

  handleChangeMethod(method: string) {
    this.paymentMethod = method
  }

  onProcessorChange(processor: Processors): void {
    this.selectedProcessor = processor;
    this.changeProcessorInstructions()
  }

  generatePdf(): void {
    this.onShowTemplateFunc(true)
    this.onSetDownloadButtonToLoading(true)

    setTimeout(() => {
      this.screenRecordService.captureScreen('voucherTemplate')
    }, 200);
  }

  onShowTemplateFunc(data: boolean): void {
    this.showTemplate = data
    this.onShowTemplate.emit(this.showTemplate)
  }

  generatePdfByImage(image: string): void {
    const pdfWidth = 210
    let voucherHeight = 300

    if (this.voucherData.voucherConfig.bankList.length === 1) {
      voucherHeight = 200
    }

    if (this.voucherData.voucherConfig.bankList.length === 2) {
      voucherHeight = 250
    }

    this.jsPdfService.addImage(
      image,
      'JPEG',
      0, //X posición en el PDF
      0, //Y posición en el PDF
      pdfWidth, //Ancho en el PDF
      voucherHeight
    );

    this.jsPdfService.save('Instrucciones de pago')
  }

  downloadImage(image: string): void {
    var a = document.createElement("a")
    a.href = image
    a.download = "Instrucciones de pago"
    a.click()
    this.onSetDownloadButtonToLoading(false)
  }

  onCreateIntervalToChangeStatus(): void {
    //Interval to update the timer
    const t = setInterval(() => {
      this.expirationTimeFormated = this.countdown.i.text as string
      if (this.expirationTimeFormated === '00:00:00' && this.voucherData.status != 'AUTORIZADO') {
        this.voucherData.status = 'EXPIRADO'
        clearInterval(t)
      }

      this.initLinearSteps()
    }, 1000)
  }

  onSetDownloadButtonToLoading(loading: boolean): void {
    this.directConnectionsVoucherInput = {
      ...this.directConnectionsVoucherInput,
      downloadButton: {
        ...this.directConnectionsVoucherInput.downloadButton,
        isLoading: loading,
      },
    }
  }

  setGetVoucherDataInterval(): void {
    setInterval(() => {
      this.getPaymentInfo()
    }, 60000)
  }

}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ICustomSelectModel } from '@angular-monorepo/ui';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'ui-clinng-double-select-form-field',
  standalone: true,
  imports: [CommonModule,FormsModule,ReactiveFormsModule,MatInputModule],
  templateUrl: './ui-clinng-double-select-form-field.component.html',
  styleUrl: './ui-clinng-double-select-form-field.component.scss',
})
export class UiClinngDoubleSelectFormFieldComponent {
  @Input() controlSelect: FormControl = new FormControl();
  @Input() controlInput: FormControl = new FormControl();
  @Input() valueOptions: ICustomSelectModel[] = []
  @Input() label: string = '';
  @Input() maxlength: number;
  @Input() minlength: number;
  @Input() errorMessage: string = '';
  @Input() type: string = 'text';
  @Input() width: number = 215;
  @Input() widthSelect: number = 63.397;
  @Input() widthInput: number = 215;
  @Output() onChangeEvent: EventEmitter<any> = new EventEmitter<any>();

  onChange($event: any): void {
    this.onChangeEvent.emit($event.target.value);
  }

  
  hasError(): boolean {
    return this.controlSelect.invalid && (this.controlSelect.dirty || this.controlSelect.touched) || 
            this.controlInput.invalid && (this.controlInput.dirty || this.controlInput.touched);

  }
}

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutStatusPseComponent } from '@angular-monorepo/checkout-form-clinng';

@Component({
  selector: 'angular-monorepo-checkout-status-movii',
  standalone: true,
  imports: [CommonModule,CheckoutStatusPseComponent],
  templateUrl: './checkout-status-movii.component.html',
  styleUrl: './checkout-status-movii.component.scss',
})
export class CheckoutStatusMoviiComponent {}

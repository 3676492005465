import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { ICustomSelectModel } from '@angular-monorepo/ui';

@Component({
  selector: 'ui-clinng-select-form-field',
  standalone: true,
  imports: [CommonModule,FormsModule,ReactiveFormsModule,MatInputModule,MatSelectModule],
  templateUrl: './ui-clinng-select-form-field.component.html',
  styleUrl: './ui-clinng-select-form-field.component.scss',
})
export class UiClinngSelectFormFieldComponent {
  @Input() control: FormControl = new FormControl();
  @Input() label: string;
  @Input() width: number = 312;
  @Input() errorMessage: string;
  @Input() placeholder: string = 'Ingresar número...';

  @Input() valueOptions: ICustomSelectModel[] = []
  @Input() default_value: string | null = null
  @Output() onChangeEvent: EventEmitter<ICustomSelectModel> = new EventEmitter<ICustomSelectModel>()
  @Input() multiple: boolean = false
  @Input() required: boolean = false
  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.default_value) {
      this.control.setValue(this.default_value)
      return
    }

    if (this.multiple) {
      this.control.setValue([this.valueOptions[0]['value']])
      return
    }

    this.onChangeEvent.emit({
      label: this.valueOptions[0] ? this.valueOptions[0]['label'] : '',
      value: null,
    })

  
    this.cd.detectChanges();
  }

  onChange($event:any): void {
    // Multiple selection emit an array of ICustomSelectModel

    if (this.multiple) {
      let values: ICustomSelectModel[] = []

      this.valueOptions.forEach((customSelect: ICustomSelectModel) => {

        $event.value.forEach((result: string) => {
          if (result === customSelect.value) {
            values.push({
              label: customSelect.label,
              value: result
            })
          }
        })
      })

      if (!values.length) {
        this.control.setValue([this.valueOptions[0]['value']])
        this.onChangeEvent.emit([{
          label: this.valueOptions[0]['label'],
          value: this.valueOptions[0]['value']
        }] as any)
        return
      }

      this.onChangeEvent.emit(values as any)
      return
    }

    // Simple selection emit an objet of ICustomSelectModel
    const response = this.valueOptions.find((result) => {

      return $event.target.value === result.value
    })

    this.onChangeEvent.emit(response)
    this.cd.detectChanges();
  }

  hasError(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }
}

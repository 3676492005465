<article >
  <mat-toolbar class="menu" style="display: flex;justify-content: center;">
   

    <div class="menu__logo">
      <img src="./assets/svg/daviplata-logo.svg" alt="daviplata-logo" />
    </div>

    
  </mat-toolbar>
  <hr class="divisor" />
    <div style="display: flex;justify-content: center;">
      <div class="checkout" id="comprobante-pago">
    
        <ng-container >
          <div class="estado-transaccion">
            <h3>Constancia de operación</h3>
            @if(success){
              <div class="estado-transaccion__icono estado-transaccion__icono--exito">
                <img src="./assets/icon/success.svg" alt="daviplata-logo" />
              </div>
              <p class="estado-transaccion__titulo">Transacción exitosa</p>
              <p class="estado-transaccion__total estado-transaccion__total--exito">
                {{operationInfo?.operation?.currencySymbol}} {{operationInfo?.operation?.amount}} 
              </p>
            }@else{
              <div class="estado-transaccion__icono estado-transaccion__icono--error">
                <img src="./assets/icon/error.svg" alt="daviplata-logo" />
              </div>
         
              <p class="estado-transaccion__titulo">Transacción rechazada</p>
              <p class="estado-transaccion__total estado-transaccion__total--error">
                {{operationInfo?.operation?.currencySymbol}} {{operationInfo?.operation?.amount}} 
              </p>
            }
    
          </div>
        </ng-container>
    
        <div class="checkout__contenedor">
          <ng-container>
            <div class="informacion">
            
              <p class="titulo">Detalle de pago</p>
              <ng-container  >
                <div class="informacion__item">
                  <p class="informacion__item__titulo">Monto</p>
                  <p class="informacion__item__dato"> {{operationInfo?.operation?.currencySymbol}} {{operationInfo?.operation?.amount}} </p>
                </div>
    
                @if(success){
                  <div class="informacion__item">
                    <p class="informacion__item__titulo">Número de solicitud</p>
                    <p class="informacion__item__dato">{{ operationInfo?.approvalNumber}}</p>
                  </div>
                } @else{
                  <div class="informacion__item">
                    <p class="informacion__item__titulo">Error</p>
                    <p class="informacion__item__dato">{{ operationInfo?.errorDescription}}</p>
                  </div>
                }
    
                <div class="informacion__item">
                  <p class="informacion__item__titulo">Fecha y hora</p>
                  <p class="informacion__item__dato">{{ date | date: 'dd/MM/yyyy - h:mm a' }}</p>
                </div>
              </ng-container>
    
              <div class="informacion__monnet">
                <img src="./assets/svg/by-clinng.svg" alt="monnet-logo" />
              </div>
           
            </div>
          </ng-container>
    
        </div>
    
    
      </div>
    </div>
     
    
</article >
    
  
  
<article >
    <ui-clinng-static-header [logo]="'./assets/img/pse-logo.png'" [alt]="'pse-logo'" [widthLogo]="'82px'" [heightLogo]="'83px'" [heightHeader]="'100px'"></ui-clinng-static-header>
    
      @if(initLoading){
        <div class="loading">
          <ui-custom-spinner  [diameter]="'80'"  [mode]="'indeterminate'" [image]="'./assets/svg/clinning.svg'" ></ui-custom-spinner>
        </div>
      }@else{
        <div class="checkout" >
          <ng-container>
             <form-pse [bankList]="bankList" [documentValues]="documentValues"></form-pse>
             
              <div class="checkout__actions">
                <div class="checkout__actions__check">
                  <mat-checkbox [(ngModel)]="aceptoTerminos" class="checkout__actions__check__input" (click)="handleCheckboxClick($event)"></mat-checkbox>
                  <div class="checkout__actions__check__text">
                      <span class="checkout__actions__check__action">He leído y acepto los  <strong class="checkout__actions__check__action__lnk"  (click)="downloadPdf( $event)" >Términos y Condiciones y Politica de Tratamiento de Datos</strong></span>
                  </div>
              </div>
             
            
                <ui-clinng-button *ngIf="operationInfo && formPseComponent" [label]="'Pagar '+ operationInfo.sessionDetails.amount" 
                [disabled]="formPseComponent.form.invalid || !aceptoTerminos"
                (onClick)="createOperation()"
                [loading]="loading"
                 ></ui-clinng-button>
              
              
                 
              </div>
          </ng-container>
   
        </div>
      }
    </article>
    

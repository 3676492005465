import { Component, effect, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CaptureScreenService } from '@angular-monorepo/screenshot';
import { PdfJsService } from '@angular-monorepo/pdf';
import { HttpClientModule } from '@angular/common/http';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DaviplataService, DocumentType, PaymentDetails } from '@angular-monorepo/api-services';
import { CustomSpinnerComponent } from '@angular-monorepo/ui';
import { DownloadVoucherComponent } from '../download-voucher/download-voucher.component';
import  moment from 'moment';
import { CheckoutEstadoEnum } from '../../../model/states.enum';



@Component({
  selector: 'checkout-daviplata-voucher-container',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    CustomSpinnerComponent,
    DownloadVoucherComponent
  ],
  templateUrl: './checkout-daviplata-voucher-container.component.html',
  styleUrl: './checkout-daviplata-voucher-container.component.scss',
})
export class CheckoutDaviplataVoucherContainerComponent {
  @Input() operationInfo: PaymentDetails;

  estado: CheckoutEstadoEnum = CheckoutEstadoEnum.INGRESO_DATOS;
  estadoModel = CheckoutEstadoEnum;
  aceptoTerminos: boolean = false;
  aceptoPolitica: boolean = false;
  documentForm: FormGroup;
  otpForm: FormGroup;
  screenRecordService = inject(CaptureScreenService)
  jsPdfService = inject(PdfJsService)
  transactionId: string;
  verification: string;
  documents: DocumentType[] = [];
  maxlength: number;
  minlength: number;
  type: string = 'text';
  initLoading: boolean = true;
  loading: boolean = false;
  success: boolean | null = false;
  errorMessage: string = '';
  date: Date = new Date();
  download: boolean = false;
  timer: string;
  intervalRef: any;

  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private daviplataService: DaviplataService,
    private router: Router
  ) {

    effect(() => {
      if (this.screenRecordService.generatedImage()) {
        (() => {
          const pdfWidth = 210
          let voucherHeight = 150

          this.jsPdfService.addImage(
            this.screenRecordService.generatedImage(),
            'JPEG',
            0,
            0,
            pdfWidth,
            voucherHeight
          );

          this.jsPdfService.save('daviplata')
        })()
      }
    })
  }


  ngOnInit(): void {
    this.getDocuments();
    this.initForms();

    this.route.params.subscribe(params => {
      this.transactionId = params['transactionId'];
    }
    );

    this.route.queryParams.subscribe(params => {
      this.verification = params['verification'];
    });



    this.getPaymentDetails();

  }
  initForms() {
    this.documentForm = this.fb.group({
      documentNumber: ['', Validators.required],
      documentType: ['', Validators.required],
    });

    this.otpForm = this.fb.group({
      confirmValue: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
    });
  }

  getDocuments() {
    this.daviplataService.findDocumentByCountry().subscribe(
      (data: any) => {
        this.documents = data;
      },
      (error) => {
        console.error('Error en la solicitud:', error);
      }
    )
  }


  next() {
    if (this.estado === CheckoutEstadoEnum.INGRESO_DATOS) {
      this.initOperation()
      return;
    }
    if (this.estado === CheckoutEstadoEnum.INGRESO_CODIGO) {
      this.confirmOperation();
      return;
    }

    if (this.estado === CheckoutEstadoEnum.FINALIZADO) {
      if (this.success) {
        window.location.href = this.operationInfo.operation.okUrl;
      } else {
        window.location.href = this.operationInfo.operation.errorUrl;
      }
    }
  }

  back() {
    if (this.estado === CheckoutEstadoEnum.INGRESO_CODIGO) {
      this.estado = CheckoutEstadoEnum.INGRESO_DATOS;
      return;
    }
    if (this.estado === CheckoutEstadoEnum.FINALIZADO) {
      this.estado = CheckoutEstadoEnum.INGRESO_CODIGO;
      return;
    }
  }

  disabledButton() {
    if (this.estado === CheckoutEstadoEnum.INGRESO_DATOS) {
      return this.documentForm.invalid || !this.aceptoTerminos || !this.aceptoPolitica || this.loading;
    }
    if (this.estado === CheckoutEstadoEnum.INGRESO_CODIGO) {
      return this.otpForm.invalid || this.loading;
    }
    return false;
  }

  buttonText() {
    if (this.estado === CheckoutEstadoEnum.INGRESO_DATOS) {
      return 'Continuar';
    }
    if (this.estado === CheckoutEstadoEnum.INGRESO_CODIGO) {
      return `Pagar ${this.operationInfo?.operation?.currencySymbol} ${this.operationInfo?.operation?.amount} `;
    }
    return 'Ir a inicio';
  }


  generatePdf(): void {
    this.download = true;
    setTimeout(() => {
      this.screenRecordService.captureScreen('comprobante-pago')
      this.download = false;
    }, 100);
  }

  setView() {
    if (this.operationInfo.processor.name != 'Daviplata_wlt' || this.operationInfo.operation.status != '2') {
      this.router.navigate(['/error/daviplata']);
    }



    if (this.operationInfo.operation.hasSessionToken) {
      this.estado = CheckoutEstadoEnum.INGRESO_CODIGO;
    } else {
      this.estado = CheckoutEstadoEnum.INGRESO_DATOS;
    }
    this.initLoading = false;
  }

  onDocumentTypeSelectChange(code: string) {
    const document = this.documents.find(document => document.codeDocument === code);
    if (document) {
      this.documentForm.controls['documentNumber'].setValidators([Validators.required, Validators.minLength(document.lenghtInit), Validators.maxLength(document.lenghtMax)]);
      this.documentForm.controls['documentNumber'].updateValueAndValidity();
      this.type = document.type == 'text' ? 'text' : 'tel';
      this.maxlength = document.lenghtMax;
      this.minlength = document.lenghtInit;
    }
  }


  getPaymentDetails() {

    this.daviplataService.getPaymentDetails(this.transactionId, this.verification)
      .subscribe(
        {
          next: (data) => {
            this.operationInfo = data;
            this.setView();

            if (this.operationInfo.operation.expirationDate) {
              this.initLoading = true;
              this.setTimer(this.operationInfo.operation.expirationDate)
            }

          },
          error: (error) => {
            console.error('Error en la solicitud:', error);
            this.initLoading = false;
            this.router.navigate(['/error/daviplata']);
          }
        }
      );
  }

  initOperation() {
    this.loading = true;
    this.errorMessage = '';
    if (this.documentForm.invalid) {
      this.loading = false;
      this.estado = CheckoutEstadoEnum.INGRESO_DATOS;
      this._snackBar.open('Por favor, complete los campos requeridos', 'Cerrar', { duration: 2500 });
      return;
    }

    this.daviplataService.initOperation(this.transactionId, this.documentForm.value, this.operationInfo.verificationResult)
      .subscribe({
        next: (data: any) => {
          if (data.success) {
            this.estado = CheckoutEstadoEnum.INGRESO_CODIGO;
            this.operationInfo.operation.expirationDate = data.expirationDate;
            this.setTimer(data.expirationDate);
          } else {
            this.success = data.success;
            this.operationInfo.errorDescription = data?.errorDescription;
            this.estado = CheckoutEstadoEnum.FINALIZADO;
          }


          this.loading = false;
        },
        error: (error) => {
          if (error.error.errorDetails[0].code == 'documentNumber') {
            this.errorMessage = 'Documento inválido'
          } else {
            this.errorMessage = error.error.errorDetails[0].message;
          }
          this.loading = false;
        }
      });
  }

  confirmOperation() {
    this.loading = true;
    this.daviplataService.confirmOperation(this.transactionId, this.otpForm.value, this.operationInfo.verificationResult)
      .subscribe({
        next: (data: any) => {
          this.success = data.success;
          this.operationInfo.approvalNumber = data.approvalNumber;
          this.operationInfo.errorDescription = data.errorDescription;
          this.estado = CheckoutEstadoEnum.FINALIZADO;
          this.loading = false;
        },
        error: (error) => {
          this.loading = false;
          this.router.navigate(['/error/daviplata']);
          console.error('Error en la solicitud:', error);
        }
      });
  }

  onFormatInput() {
    this.errorMessage = '';

    if (this.type == 'tel') {
      let formatDocument = this.documentForm.controls['documentNumber'].value.replace(/\D/g, '');
      this.documentForm.controls['documentNumber'].setValue(formatDocument);
    }
  }

  onFormatInputOTP() {
    let formatOpt = this.otpForm.controls['confirmValue'].value.replace(/\D/g, '');
    this.otpForm.controls['confirmValue'].setValue(formatOpt);
  }

  setTimer(expirationDateResponse: string) {
    if (this.intervalRef) { clearInterval(this.intervalRef); }

    const currentDate = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
    const expirationDate = moment.utc(expirationDateResponse).format('YYYY-MM-DDTHH:mm:ss');
    const finalexpirationDate = moment.utc(expirationDate);
    const finalCurrentDate = moment.utc(currentDate);


    this.intervalRef = setInterval(() => {
      if (finalexpirationDate.isBefore(finalCurrentDate) || this.timer === '00 : 00') {
        clearInterval(this.intervalRef);
        this.expirateOperation();
        return;
      }
      this.initLoading = false;
      let differenceInSeconds = finalexpirationDate.diff(moment.utc(), 'seconds');
      this.timer = moment.utc(differenceInSeconds * 1000).format('mm : ss');

    }, 1000);
  }

  downloadPdf(ruta: string, nombrePDF: string, event: MouseEvent): void {
    event.stopPropagation();
    const link = document.createElement('a');
    link.href = ruta;
    link.download = nombrePDF;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  handleCheckboxClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  expirateOperation() {
    this.transactionId, this.otpForm.value, this.operationInfo.verificationResult
    this.daviplataService.expirateOperation(this.transactionId, this.operationInfo.verificationResult).subscribe(
      ({
        next: (data: any) => {
          clearInterval(this.intervalRef);
          this.router.navigate(['/error-status/daviplata']);
          this.initLoading = false;
        },
        error: (error) => {
          clearInterval(this.intervalRef);
          this.router.navigate(['/error-status/daviplata']);
          this.initLoading = false;

        }
      })
    )
  }

}

import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

export interface DetailsModel{
  title:string;
  subtitle?:string;
  mount:string;
  img:string;
  color:string;
}
@Component({
  selector: 'ui-clinng-status-alert',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './ui-clinng-status-alert.component.html',
  styleUrl: './ui-clinng-status-alert.component.scss',
})
export class UiClinngStatusAlertComponent {
  @Input() detailsModel: DetailsModel;
  @Input() mount: string = '';
}

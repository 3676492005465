import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';

@Component({
  selector: 'download-voucher',
  standalone: true,
  imports: [CommonModule,MatIconModule,MatToolbarModule],
  templateUrl: './download-voucher.component.html',
  styleUrl: './download-voucher.component.scss',
})
export class DownloadVoucherComponent {
  @Input() operationInfo: any;
  @Input()success:boolean | null = true;
  date:Date = new Date();
}

import { Route } from '@angular/router';
import { VoucherComponent } from './pages/voucher/voucher.component';
import { ErrorComponent } from './pages/error/error.component';
import { CheckoutFormNequiComponent } from './pages/checkout-form-nequi/checkout-form-nequi.component';
import { CheckoutFormPseComponent } from './pages/checkout-form-pse/checkout-form-pse.component';
import { CheckoutClinngVoucherComponent } from './pages/checkout-clinng-voucher/checkout-clinng-voucher.component';
import { CheckoutFormMoviiComponent } from './pages/checkout-form-movii/checkout-form-movii.component';
import { CheckoutStatusMoviiComponent } from './pages/checkout-status-movii/checkout-status-movii.component';

export const appRoutes: Route[] = [
  {
    path: 'daviplata/form/:transactionId',
    component: VoucherComponent,
  },
  {
    path: 'nequi/form/:transactionId',
    component: CheckoutFormNequiComponent,
  },
  {
    path: 'movii/form/:verification',
    component: CheckoutFormMoviiComponent,
  },
  {
    path: 'movii/status/:transactionId',
    component: CheckoutStatusMoviiComponent,
  },
  {
    path: 'clinng/voucher/:transactionId',
    component: CheckoutClinngVoucherComponent,
  },
  {
    path: 'error/:id',
    component: ErrorComponent
  },
  {
    path: 'error-status/:id',
    component: ErrorComponent
  },
  {
    path: '**',
    component: ErrorComponent,
  },
];

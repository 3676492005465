import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NequiService } from '@angular-monorepo/api-services';
import { CheckoutNequiFormContainerComponent } from '@angular-monorepo/checkout-form-clinng';
@Component({
  selector: 'angular-monorepo-checkout-form-nequi',
  standalone: true,
  imports: [CommonModule, CheckoutNequiFormContainerComponent],
  templateUrl: './checkout-form-nequi.component.html',
  styleUrl: './checkout-form-nequi.component.scss',
  providers: [NequiService],
})
export class CheckoutFormNequiComponent {}

import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomSpinnerComponent } from '@angular-monorepo/ui';
import { DetailsModel, TicketDetails, UiClinngButtonComponent, UiClinngDownloadButtonComponent, UiClinngStaticHeaderComponent, UiClinngStatusAlertComponent, UiClinngTicketInfoComponent } from '@angular-monorepo/clinng-ui';
import { DownloadPseVoucherComponent } from '../checkout-form-pse/download-pse-voucher/download-pse-voucher.component';
import { FormPseComponent } from '../checkout-form-pse/form-pse/form-pse.component';
import { CaptureScreenService } from '@angular-monorepo/screenshot';
import { GetOperationMoviiResponse, MoviiService, MoviiSessionDetails, PaymentDetails } from '@angular-monorepo/api-services';
import { IconsEnum } from '../../model/icons.enum';
import { ActivatedRoute, Router } from '@angular/router';
import  moment from 'moment';

@Component({
  selector: 'checkout-status-pse',
  standalone: true,
  imports: [CommonModule, CustomSpinnerComponent,
    UiClinngButtonComponent,
    UiClinngDownloadButtonComponent,
    UiClinngTicketInfoComponent,
    UiClinngStatusAlertComponent,
    UiClinngStaticHeaderComponent,
    FormPseComponent,
    DownloadPseVoucherComponent],
  templateUrl: './checkout-status-pse.component.html',
  styleUrl: './checkout-status-pse.component.scss',
})
export class CheckoutStatusPseComponent {
  screenRecordService = inject(CaptureScreenService)
  detailsModel: DetailsModel;
  operationInfo: GetOperationMoviiResponse;
  ticketDetails: TicketDetails[] = []
  download: boolean = false;
  transactionId: string;
  verification: string;
  initLoading = true;
  constructor(private moviiService: MoviiService, private route: ActivatedRoute,private router:Router) { }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.transactionId = params['transactionId'];
    }
    );
    this.route.queryParams.subscribe(params => {
      this.verification = params['verification'];
    });

    this.moviiService.getOperation(this.transactionId, this.verification).subscribe({
      next: (data: GetOperationMoviiResponse) => {
        this.operationInfo = data;
        this.initLoading = false;
        this.setTicketDetails()
        this.setAlertDetails()
      },
      error: (error) => {
        console.error(error);
        this.initLoading = false;
        this.router.navigate(['/error', 'movii']);
      },
      complete: () => {
      }
    });
  }
  downloadVoucher() {
    this.download = true;
    setTimeout(() => {
      this.screenRecordService.captureWithPromise('comprobante-pago').then((imageData: string) => {
        this.download = false;
        const link = document.createElement('a');
        link.href = imageData;
        link.download = 'pse.png';
        link.click();
      });
    }, 100);
  }

  redirect() {
    if (this.operationInfo.operation.state === '5') {
      window.location.href = this.operationInfo.operation.okUrl;
    } else {
      window.location.href = this.operationInfo.operation.errorUrl;
    }
  }

  setTicketDetails() {
    this.ticketDetails = [
      {
        title: 'Nombres y apellidos',
        value: `${this.operationInfo?.customer?.name}`
      },
      {
        title: 'Monto',
        value: `${this.operationInfo?.operation?.currencySymbol} ${this.operationInfo?.operation?.amount}`
      },
      {
        title: 'Banco',
        value: `${this.operationInfo?.bank?.name}`
      },
      {
        title: 'Número de solicitud',
        value: this.operationInfo?.operation?.idOperation
      },
      {
        title: 'Fecha y hora',
        value: moment(this.operationInfo?.operation?.creationDate).format('DD/MM/YYYY HH:mm')
      }
    ]
  }

  setAlertDetails() {
    if (this.operationInfo?.operation?.state === '5') {
      this.detailsModel = {
        title: 'Pago exitoso',
        mount: `${this.operationInfo?.operation?.currencySymbol} ${this.operationInfo?.operation?.amount}`,
        img: IconsEnum.SUCCESS,
        color: 'blue',
      }
    } else if (this.operationInfo?.operation?.state === '3' ) {
      this.router.navigate(['/error-status', 'movii']);
    } else if (this.operationInfo?.operation?.state === '6') {
      this.detailsModel = {
        title: 'Pago rechazado',
        mount: `${this.operationInfo?.operation?.currencySymbol} ${this.operationInfo?.operation?.amount}`,
        img: IconsEnum.ERROR,
        color: 'red',
      }
    } else {
      this.detailsModel = {
        title: 'Pago en proceso',
        subtitle: 'PSE esta procesando tu pago, espera unos minutos',
        mount: `${this.operationInfo?.operation?.currencySymbol} ${this.operationInfo?.operation?.amount}`,
        img: IconsEnum.IN_PROGRESS,
        color: 'blue',
      }
    }
  }

  showDownloadButton() {
    return  this.operationInfo?.operation?.state === '6' || this.operationInfo?.operation?.state === '5';
  }
}

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckoutPseFormContainerComponent } from '@angular-monorepo/checkout-form-clinng';

@Component({
  selector: 'angular-monorepo-checkout-form-movii',
  standalone: true,
  imports: [CommonModule,CheckoutPseFormContainerComponent],
  templateUrl: './checkout-form-movii.component.html',
  styleUrl: './checkout-form-movii.component.scss',
})
export class CheckoutFormMoviiComponent {}

<!-- [formGroup]="formGroup" -->
<div class="input-contenedor input-contenedor--union" [style.width.px]="width">
    <label class="input-contenedor__label">{{label}} </label>
  
    <div class="input-contenedor__union" [style.width.px]="width" >

      <select  [style.width.px]="widthSelect" class="input-contenedor__select" 
      name="select" (change)="onChange($event)" [ngClass]="{'error': hasError() }" 
      [formControl]="controlSelect">

        <option *ngFor="let document of valueOptions" [value]="document.value">{{document.label}}</option>
    </select>
      <input
      [style.width.px]="widthInput" 
      [ngClass]="{'error': hasError() }"
        class="input-contenedor__input"
        type="tel"
        placeholder="Ingresar número"
        [formControl]="controlInput"
        [maxlength]="maxlength"
      />

    </div>
    <mat-error class="form-error-message" *ngIf="hasError()">
      {{ errorMessage }}
    </mat-error>
  </div>
  
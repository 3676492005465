<mat-toolbar class="menu" [style.height]="heightHeader ? heightHeader : '80px'">
    <button mat-icon-button  *ngIf="false">
      <mat-icon>arrow_back_ios</mat-icon>
    </button>

    <div class="menu__logo">
      <img [src]="logo" [alt]="alt" [style.width]="widthLogo ? widthLogo : '103px'" [style.height]="heightLogo ? heightLogo : '48px'" />
    </div>

    <div></div>
  </mat-toolbar>
  <hr class="divisor" />
<!-- <div class="modal">
    <div class="content">
     
    </div>

    <ui-clinng-button [label]="'Retornar al comercio'" (onClick)="redirect()"></ui-clinng-button>
</div> -->


<mat-dialog-content>
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0148 48C37.2643 48 48 37.2578 48 24C48 10.7422 37.2643 0 24.0148 0C10.7653 0 0 10.7422 0 24C0 37.2578 10.7653 48 24.0148 48Z" fill="#EB0046"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.4506 14.4528C26.4506 13.0989 25.3517 12 23.9978 12C22.6422 12 21.5449 13.0989 21.5449 14.4528C21.5449 15.8068 22.6422 16.9057 23.9978 16.9057C25.3517 16.9057 26.4506 15.8068 26.4506 14.4528ZM26.4506 33.9268V21.4362C26.4506 20.2905 25.3517 19.3607 23.9978 19.3607C22.6438 19.3607 21.5449 20.2905 21.5449 21.4362V33.9268C21.5449 35.0724 22.6438 36.0022 23.9978 36.0022C25.3517 36.0022 26.4506 35.0724 26.4506 33.9268Z" fill="white"/>
        </svg>

        <h3>¡Ups! Ocurrió un problema</h3>

        <h5>Lo sentimos, esta transacción expiró.
            Intenta un nuevo pago.</h5>



            <img style="width: 71.52px;
height: 19.463px;" src="./assets/svg/clinning.svg" alt="">
</mat-dialog-content>
<mat-dialog-actions>
    <ui-clinng-button [label]="'Retornar al comercio'" (onClick)="redirect()"></ui-clinng-button>
</mat-dialog-actions>
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UiClinngErrorPageComponent } from '@angular-monorepo/clinng-ui';
@Component({
  selector: 'angular-monorepo-error',
  standalone: true,
  imports: [CommonModule,
  UiClinngErrorPageComponent],
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
})
export class ErrorComponent {
  id?: string;
  logo:string;
  alt:string;
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params.get('id') || 'clinng';
      if(this.id == 'nequi'){
        this.logo = './assets/svg/nequi-logo.svg';
        this.alt = 'Nequi';
      }else if(this.id == 'daviplata'){
        this.logo = './assets/svg/daviplata-logo.svg';
        this.alt = 'Daviplata';
      }else if(this.id === 'movii'){
        this.logo = './assets/img/pse-logo.png';
        this.alt = 'Movii';
      }
      else{
        this.logo = './assets/svg/clinning.svg';
        this.alt = 'Clinng';
      }
      
    });
  }

}

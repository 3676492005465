<article>
    <ui-clinng-static-header [logo]="'./assets/img/pse-logo.png'" [alt]="'pse-logo'" [widthLogo]="'82px'" [heightLogo]="'83px'" [heightHeader]="'100px'"></ui-clinng-static-header>
        <div class="checkout">
        <div></div>
        <div>
            <h5>Constancia de operación</h5>
            <ui-clinng-status-alert [detailsModel]="detailsModel" [mount]="'$ 3,000.00'"></ui-clinng-status-alert>
        </div>
       
        <ui-clinng-ticket-info [title]="'Detalle de pago'" [ticketDetails]="ticketDetails"></ui-clinng-ticket-info>


        <div></div>

    </div>
</article>


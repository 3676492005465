import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'ui-clinng-form-field',
  standalone: true,
  imports: [CommonModule,FormsModule,ReactiveFormsModule,MatInputModule],
  templateUrl: './ui-clinng-form-field.component.html',
  styleUrl: './ui-clinng-form-field.component.scss',
})
export class UiClinngFormFieldComponent {
  @Input() control: FormControl = new FormControl();
  @Input() label: string;
  @Input() width: number = 312;
  @Input() errorMessage: string;
  @Input() placeholder: string = 'Ingresar número...';
  @Output() onChangeEvent: EventEmitter<string> = new EventEmitter<string>();
  
  hasError(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }

  onChange($event: any): void {
    this.onChangeEvent.emit($event.target.value);
  }
}
